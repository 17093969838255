import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors, constants, namedColors } from "@propertysimple/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const shadowColor = "hsl(216, 7%, 87%)";
const shadowColorFocused = namedColors.LIGHT_BLUE;
export const shadowPixels = "0 2px 6px";
const shadowPixelsFocused = "0 0 3px";

export const InputElement = styled.input`
  border: none;
  border-radius: ${constants.BORDER_RADIUS};
  box-sizing: border-box;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
  padding: 1px 0.75rem 0 !important;
  width: 100%;
  ${(props) => props.withIcon && "padding-left: 2.25rem !important;"}
  ${(props) => !props.error && "margin-bottom: 1rem;"}
  &:focus {
    outline: 1px solid #ccc;
  }
  &:autofill,
  &:autofill:focus,
  &:-webkit-autofill {
    background: var(--clr-black) !important;
  }
  &::placeholder {
    color: var(--clr-default-grey);
  }
`;

export const TextareaElement = styled.textarea`
  border-radius: ${constants.BORDER_RADIUS};
  border: none;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0.625rem 0.75rem;
  height: 100%;
  width: 100%;

  &:focus {
    outline: 1px solid #ccc;
  }
`;

const Title = styled.label`
  font-weight: bold;
`;

const InputWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
  }
`;

export const Error = styled.div`
  color: ${colors.ERROR};
  margin: 0 0 0 0.75rem;
  font-size: 0.75rem;
`;

export const Input = ({ autoFocus, error, label, icon, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    if (autoFocus && ref?.current) {
      ref.current.focus();
    }
  }, [autoFocus, ref]);

  const value = ref?.current?.value;

  return (
    <label>
      {label && <Title>{label}</Title>}
      <InputWrapper>
        <InputElement ref={ref} error={error} withIcon={!!icon} {...props} />
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: value ? "var(--clr-black)" : "var(--clr-default-grey)",
            }}
          />
        )}
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </label>
  );
};

export const Textarea = ({ className, error, label, ...props }) => {
  return (
    <label className={className}>
      {label && <Title>{label}</Title>}
      <TextareaElement error={error} {...props} />
      {error && <Error>{error}</Error>}
    </label>
  );
};
