import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const factorFor = (borderSize) => {
  if (borderSize < 100) {
    return 20;
  } else if (borderSize < 200) {
    return 25;
  } else {
    return 50;
  }
};

const getBorderThickness = (radius) => {
  return Math.round(radius / factorFor(radius));
};

const RoundImageComponent = styled.img`
  ${({ border, size }) => {
    let radius = size / 2;
    if (border) {
      radius -= getBorderThickness(size);
    }
    return `
      border-radius: ${radius}px;
      height: ${2 * radius}px;
      object-fit: cover;
      min-height: ${2 * radius}px;
      min-width: ${2 * radius}px;
      width: ${2 * radius}px;
      min-width: ${2 * radius}px;
      max-width: ${2 * radius}px;
    `;
  }}
`;

const Border = styled.div`
  ${({ border, size }) => {
    if (border) {
      return `
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: ${getBorderThickness(size)}px solid #FFFFFF;
        border-radius: ${size + getBorderThickness(size)}px;
      `;
    }
    return "";
  }}
  ${({ size }) => `
      height: ${size}px;
      min-height: ${size}px;
      min-width: ${size}px;
      width: ${size}px;
      min-width: ${size}px;
      max-width: ${size}px;
    `}}
`;

const Loading = ({ size }) => {
  return (
    <ContentLoader
      speed={2}
      width={size}
      height={size}
      viewBox="0 0 size size"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle
        cx={Math.floor(size / 2)}
        cy={Math.floor(size / 2)}
        r={Math.floor(size / 2)}
      />
    </ContentLoader>
  );
};

// Workaround to add props documentation to Storybook
const RoundImage = ({ border, className, loading, size, style, ...props }) => {
  let content;
  if (loading) {
    content = <Loading size={size - 10} />;
  } else {
    content = <RoundImageComponent {...{ border, size }} {...props} />;
  }

  return (
    <Border border={border} className={className} size={size} style={style}>
      {content}
    </Border>
  );
};

RoundImage.defaultProps = {
  border: true,
};

RoundImage.propTypes = {
  alt: PropTypes.string,
  /** Add border to image */
  border: PropTypes.bool,
  /** Image size in pixels */
  size: PropTypes.number.isRequired,
};

export default RoundImage;
