import React from "react";
import ContactModal from "components/ContactModal";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { useRollbar } from "@rollbar/react";
import { trackEvent } from "lib/tracking";

export const mutation = gql`
  mutation createInteractionFromLeadForm($input: CreateInteractionInput!) {
    createInteraction(create_interaction_input: $input) {
      interaction_name
      __typename
    }
  }
`;

function notifyListhub() {
  if (!window.lh) return;
  window.lh("submit", "AGENT_EMAIL_SENT", {}); //third argument is required
}

export function getMutationStatus({ data, loading, error }) {
  if (loading) return "loading";
  if (error) return "error";
  if (data?.createInteraction) return "success";
  return "idle";
}

function AgentContactModal({
  crmUser,
  initialMessageBody,
  listingID,
  articleID,
  onClose,
  collectionID,
  makeAppointment,
}) {
  const rollbar = useRollbar();
  return (
    <Mutation
      mutation={mutation}
      onCompleted={() =>
        trackEvent("lead", "AW-694441744/pOJGCM6v8toBEJCukcsC")
      }
    >
      {(createInteraction, { data, loading, error }) => {
        return (
          <ContactModal
            initialMessageBody={initialMessageBody}
            twilioSmsComplianceCheckboxLabel="Text me info about your listings or services!"
            mutationStatus={getMutationStatus({ data, loading, error })}
            makeAppointment={makeAppointment}
            title="Contact Agent"
            onClose={onClose}
            onSubmit={async ({ name, email, phone, message }) => {
              const variables = {
                input: {
                  crm_user_id: crmUser?.id,
                  wishlist_id: collectionID,
                  listing_id: listingID,
                  medium_article_id: articleID,
                  interaction_name: "contact_agent",
                  interaction_detail: {
                    contact_name: name,
                    contact_email: email,
                    contact_phone: phone,
                    message: message,
                  },
                },
              };
              try {
                await createInteraction({ variables });
              } catch (error) {
                rollbar.error("Error in AgentContactModal", {
                  error,
                  variables,
                });
              }
              notifyListhub();
            }}
          />
        );
      }}
    </Mutation>
  );
}

export default AgentContactModal;
