import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const GradientBackground = (firstColor, secondColor) => `
  color: #FFFFFF;
  background : -moz-linear-gradient(6.82% 144.98% 30deg,${firstColor} 3.57%,${secondColor} 100%);
  background : -webkit-linear-gradient(30deg, ${firstColor} 3.57%, ${secondColor} 100%);
  background : -webkit-gradient(linear,6.82% 144.98% ,93.18% -44.98% ,color-stop(0.0357,${firstColor} ),color-stop(1,${secondColor} ));
  background : -o-linear-gradient(30deg, ${firstColor} 3.57%, ${secondColor} 100%);
  background : -ms-linear-gradient(30deg, ${firstColor} 3.57%, ${secondColor} 100%);
  background : linear-gradient(60deg, ${firstColor} 3.57%, ${secondColor} 100%);
`;

const ButtonWrapper = styled.button`
  outline: none;
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.center &&
    `
    display: block;
    margin-left: auto;
    margin-right: auto;
  `}
  ${(props) => props.bold && "font-weight: bold;"}
  ${(props) => props.fullWidth && "width: 100%;"}
  border-radius: 6px;
  border: 0;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  cursor: pointer;

  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  ${(props) => GradientBackground(props.firstColor, props.secondColor)}
  ${(props) =>
    props.transparent &&
    "background: #ffffff; color: #4A4A4A; border: 1px solid #4A4A4A;"}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
`;

const GradientButton = ({ color, fullWidth, ...props }) => {
  const additionalProps = {};
  if (color === "purple") {
    props.firstColor = "#53C9F7";
    props.secondColor = "#AC00BD";
  }

  return (
    <ButtonWrapper fullWidth={fullWidth} {...additionalProps} {...props}>
      {props.children}
    </ButtonWrapper>
  );
};

GradientButton.defaultProps = {
  firstColor: "rgba(241, 90, 41, 1)",
  secondColor: "rgba(255, 204, 39, 1)",
};

GradientButton.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};

export const PurpleGradientButton = ({ children, ...props }) => (
  <GradientButton firstColor="#53C9F7" secondColor="#AC00BD" {...props}>
    {children}
  </GradientButton>
);

export default GradientButton;
