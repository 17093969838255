import React from "react";
import { css } from "styled-components";
import PropTypes from "prop-types";
import { pinkColor } from "lib/colors";

const COLORS = {
  gradientPurplePink: {
    background: "linear-gradient(60deg,#AC00BD 3.57%,#ff3867 100%)",
    default: "white",
    hover: "white",
  },
  gradientBluePurple: {
    background: "linear-gradient(60deg,#53C9F7 3.57%,#AC00BD 100%)",
    default: "white",
    hover: "white",
  },
  outlinedBlue: {
    background: "#FFFFFF",
    default: "#45B3DE",
    hover: "#7CC9E7",
    backgroundDisabled: "#BBBDC0",
  },
  outlinedGreen: {
    background: "#85CA3F",
    default: "#FFFFFF",
    hover: "#FFFFFF",
  },
  outlinedPink: {
    default: "#FF3867",
    background: "#FFFFFF",
    hover: "#FF3867",
    hoverBackground: "#FAFAFA",
    disabledBorder: "#ff698d",
    disabledColor: "#ff698d",
    disabledBackground: "#e6e6e6",
  },
  purpleGradient: {
    default: "#FFFFFF",
    hover: "#FFFFFF",
    backgroundImage: "linear-gradient(257deg, #FF3867, #AC00BD)",
  },
  blue: {
    background: "#45b3de",
    default: "#45b3de",
    borderColor: "#45b3de",
    color: "#FFFFFF",
    hover: "#FFFFFF",
  },
  pink: {
    background: pinkColor,
    default: pinkColor,
    borderColor: pinkColor,
    color: "#FFFFFF",
    hover: "#FFFFFF",
  },
  purple: {
    default: "#AC00BD",
    hover: "#710887",
  },
};

const buttonLinkCss = ({
  bold,
  border = true,
  center = false,
  fontSize,
  hideBelow,
  disabled,
  horizontalPadding = "1rem",
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  rounded,
  verticalPadding = "0.5rem",
  padding,
  ...props
}) => {
  const themeName = props.name;
  const borderRadius = rounded ? "1rem" : "0.5rem";
  const theme = COLORS[themeName];
  if (!theme) {
    console.error(`No theme named '${themeName}'`);
  }
  const { background, borderColor, color } = theme || {};

  return css`
    // For some reason emotion is adding class name at this point, which breaks
    // the first css prop, so a colon is added
    background: ${theme && background ? background : "#FFFFFF"};
    border-radius: ${borderRadius};
    border: 1px solid ${theme && (borderColor || theme.default)}!important;
    box-sizing: border-box;
    color: ${theme && (color || theme.default)};
    cursor: pointer;
    font-size: ${fontSize || "1rem"};
    margin-top: 0;
    padding: ${verticalPadding} ${horizontalPadding};
    ${padding ? `padding: ${padding};` : ""}
    font-family: 'AdelleSans'!important;
    ${bold && "font-weight: bold!important;"}

    &:disabled {
      border-color: ${theme?.hover};
      color: ${theme?.hover};
    }

    &:hover {
      border-color: ${theme?.hover};
      color: ${theme?.hover};
      ${theme &&
      theme.hoverBackground &&
      `background: ${theme.hoverBackground};`}
    }

    &:disabled,
    &[disabled] {
      background: ${theme?.backgroundDisabled};
      color: ${theme?.colorDisabled};
    }

    ${!border &&
    `
      border: none!important;
      padding-left: 0;
      padding-right: 0;
    `}
    text-align: center;
    text-decoration: none;
    ${theme?.backgroundImage && `background-image: ${theme.backgroundImage};`}

    ${center &&
    `
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: max-content;
    `}
    ${disabled &&
    `
      background: ${theme?.disabledBackground};
      border-color: ${theme?.disabledBorder};
      color: ${theme?.disabledColor};
    `}
    ${marginTop && `margin-top: ${marginTop};`}
    ${marginBottom && `margin-bottom: ${marginBottom};`}
    ${marginRight && `margin-right: ${marginRight};`}
    ${marginLeft && `margin-left: ${marginLeft};`}
    ${hideBelow &&
    `
      @media not all and (min-width: ${hideBelow}) {
        display: none;
      }
    `}
  `;
};

export const Button = ({
  children,
  disabled,
  name,
  horizontalPadding,
  verticalPadding,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  ...props
}) => {
  return (
    <button
      css={buttonLinkCss({
        name,
        horizontalPadding,
        disabled,
        marginRight,
        marginLeft,
        marginTop,
        marginBottom,
        verticalPadding,
      })}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.oneOf(Object.keys(COLORS)),
};

export const A = ({
  center,
  children,
  fontSize,
  hideBelow,
  horizontalPadding,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  disabled,
  name,
  newTab,
  rounded,
  verticalPadding,
  padding,
  ...props
}) => {
  const customProps = newTab
    ? {
        rel: "noopener noreferrer",
        target: "_blank",
      }
    : {};

  return (
    <a
      css={buttonLinkCss({
        center,
        fontSize,
        hideBelow,
        horizontalPadding,
        disabled,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        name,
        rounded,
        verticalPadding,
        padding,
      })}
      {...customProps}
      {...props}
    >
      {children}
    </a>
  );
};

A.propTypes = {
  name: PropTypes.oneOf(Object.keys(COLORS)),
  newTab: PropTypes.bool,
};

export default buttonLinkCss;
