import styled from "styled-components";

const defaultMargin = "1rem";

export default styled.div`
  background-color: #bfbfbf;
  height: 1px;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom || defaultMargin};
  margin-top: ${(props) => props.marginTop || defaultMargin};
`;
