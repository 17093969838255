export function trackEvent(eventName, sendTo) {
  if (!window?.gtag) return;
  if (eventName === "lead") {
    const gtagData = {
      value: 1,
      currency: "USD",
    };
    if (sendTo) {
      gtagData.send_to = sendTo;
    }
    window.gtag("event", "HomeBuyerLead", gtagData);
  }
}
