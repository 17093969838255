import React from "react";
import styled from "styled-components";
import { colors, constants } from "@propertysimple/styles";
import {
  BackgroundImage,
  DatePicker,
  PillboxNav,
} from "@propertysimple/components";
import { useContactModalContext } from "../state";
import DateAndModalitySubtitle from "./DateAndModalitySubtitle";

const Wrapper = styled.div`
  background: ${colors.BG};
  --widget-height: 3.125rem;
  h2 {
    font-size: 1rem;
    margin: 0 0 0.75rem;
  }
  button {
    color: ${colors.BG};
    height: var(--widget-height);
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: ${constants.BORDER_RADIUS};
    border: none;
    background-image: linear-gradient(266deg, #ffcc27, var(--clr-pink));
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const StyledPillboxNav = styled(PillboxNav)`
  width: 100%;
  a {
    text-align: center;
    line-height: var(--widget-height);
    width: 50%;
  }
  nav,
  a {
    height: var(--widget-height);
  }
`;

const StyledDatePicker = styled(DatePicker)`
  --color: var(--clr-pink);
  display: block;
  margin-bottom: 1.5rem;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
    }
  }
  input {
    height: var(--widget-height) !important;
    line-height: var(--widget-height) !important;
    font-size: 1.25rem;
    padding-top: 0.25rem;
    font-weight: normal !important;
  }
  svg {
    top: calc(var(--widget-height) / 2 - 0.5rem);
  }
`;

function getMaxDate() {
  const now = new Date();
  const maxDate = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    now.getDate(),
  );
  maxDate.setHours(0, 0, 0, 0);
  return maxDate;
}

export default function SelectDateAndModalityForm({ photo }) {
  const { state, dispatch } = useContactModalContext();
  return (
    <Wrapper>
      <StyledPillboxNav
        options={[
          ["in_person", "In-Person"],
          ["video_call", "Video Chat"],
        ]}
        selected={state.modality}
        onClick={(value) => dispatch({ type: "setModality", value })}
        fgColor="var(--clr-pink)"
      />
      <h2>Select a preferred day</h2>
      <StyledDatePicker
        date={state.date}
        minDate={new Date()}
        maxDate={getMaxDate()}
        setDate={(value) => dispatch({ type: "setDate", value })}
      />
      <button
        onClick={() => {
          dispatch({
            type: "setSubtitle",
            value: <DateAndModalitySubtitle />,
          });
          dispatch({ type: "setStep", value: "collectMessage" });
        }}
      >
        Request This Day
      </button>
      <div style={{ position: "relative", height: "300px" }}>
        <BackgroundImage src={photo} />
      </div>
    </Wrapper>
  );
}
