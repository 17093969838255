import React from "react";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { AgentInformationCrmUserFragment } from "components/AgentInformation";
import { useRouter } from "next/router";
import GradientButton from "components/Button/gradient";
import Link from "next/link";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { Input, TextareaElement } from "components/NewForm";
import NumberFormat from "react-number-format";
import { namedColors } from "@propertysimple/styles";
import { Dots, PHONE_NUMBERS, isEmailValid } from "@propertysimple/components";
import { useContactModalContext, hasError } from "../state";

const Wrapper = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  input,
  textarea {
    border: 1px solid var(--clr-med-grey);
    &:focus {
      border-color: var(--clr-light-blue);
      border-width: 1px !important;
      outline: none;
    }
  }
  input[name="tel"] {
    ${(props) => !props.phoneHasValue && `color: ${namedColors.LIGHT_GREY};`}
  }
`;

const Subtitle = styled.h2`
  margin: 0 0 0.75rem;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5em;
  text-align: center;
`;

const MessageInputLabel = styled.h2`
  font-size: 1rem;
  margin: 0 0 0.5rem 1px;
`;

const SmallPrint = styled.div`
  color: hsl(0 0% 71%);
  font-size: 12px;
  line-height: 1.5em;
  text-align: center;
`;

const StyledGradientButton = styled(GradientButton)`
  display: block;
  background-image: linear-gradient(266deg, #ffcc27, var(--clr-pink));
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  height: 3.125rem;
  margin: 0 auto 1rem;
  min-height: 3.125rem;
  padding-top: 0.125rem;
`;

const Error = styled.div`
  color: var(--clr-error);
  text-align: center;
  margin: 0 0 0.5rem;
`;

const HelpLinks = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0.5rem;
  text-align: center;
  &,
  a,
  a:active,
  a:visited {
    color: hsl(0 0% 71%);
  }
`;

export const ContactModalCrmUserFragment = gql`
  fragment ContactModalCrmUserFragment on CrmUser {
    ...AgentInformationCrmUserFragment
  }
  ${AgentInformationCrmUserFragment}
`;

export default function CollectLeadDataForm({
  mutationStatus,
  onSubmit,
  makeAppointment,
}) {
  const router = useRouter();
  const { state, dispatch } = useContactModalContext();
  const { free_phone: doNotValidatePhone } = router.query;

  function getNameError(value, ignoreTouched) {
    if (!state.nameTouched && !ignoreTouched) return "";
    const name = cleanEntry(value);
    if (name.length < 2) return "Please enter your name";
    return "";
  }

  function getMessageError(value) {
    return ""; //message is unrestricted
  }

  function cleanEntry(value) {
    return value.trim();
  }

  function getEmailError(value, ignoreTouched) {
    if (!state.emailTouched && !ignoreTouched) return "";
    const email = cleanEntry(value);
    if (email.length === 0) return "Email is required";
    if (!isEmailValid(email)) return "Please enter a valid email";
    return "";
  }

  function cleanPhone(value) {
    return value.replace(/[^\d]/g, "");
  }

  function getPhoneError(value, ignoreTouched) {
    if (doNotValidatePhone) return "";
    if (!state.phoneTouched && !ignoreTouched) return "";
    const phone = cleanPhone(value);
    if (phone.length === 0) return "Please enter your phone number";
    // 11th digit is 1 for US numbers
    if (phone.length < 11) return "Please enter a valid 10-digit phone number";
    return "";
  }

  const buttonText = "Request more info";

  return (
    <Wrapper onSubmit={onSubmit} phoneHasValue={state.phone.length > 0}>
      {state.subtitle && <Subtitle>{state.subtitle}</Subtitle>}
      <input type="hidden" name="modality" value={state.modality} />
      <input type="hidden" name="date" value={state.date} />
      <Input
        autoFocus
        border={false}
        placeholder="Your Name"
        name="name"
        icon={faUser}
        type="text"
        value={state.name}
        onChange={(evt) =>
          dispatch({ type: "setName", value: evt.target.value })
        }
        onBlur={(evt) =>
          dispatch({
            type: "setNameError",
            value: getNameError(evt.target.value),
          })
        }
        error={state.nameError}
      />
      <Input
        border={false}
        placeholder="Email"
        name="email"
        icon={faEnvelope}
        type="text"
        value={state.email}
        onChange={(evt) =>
          dispatch({ type: "setEmail", value: cleanEntry(evt.target.value) })
        }
        onBlur={(evt) =>
          dispatch({
            type: "setEmailError",
            value: getEmailError(evt.target.value),
          })
        }
        error={state.emailError}
      />
      <NumberFormat
        format={doNotValidatePhone ? "+## (###) ###-####" : "+1 (###) ###-####"}
        mask="_"
        placeholder="Phone Number"
        name="tel"
        type="tel"
        value={state.phone}
        onChange={(evt) => {
          dispatch({ type: "setPhone", value: evt.target.value });
        }}
        onBlur={(evt) =>
          dispatch({
            type: "setPhoneError",
            value: getPhoneError(evt.target.value),
          })
        }
        error={state.phoneError}
        customInput={Input}
        icon={faPhone}
      />
      <MessageInputLabel style={{ fontWeight: "normal" }}>
        Message
      </MessageInputLabel>
      <TextareaElement
        rows={1}
        border={false}
        name="message"
        value={state.message}
        onChange={(evt) =>
          dispatch({ type: "setMessage", value: evt.target.value })
        }
        onBlur={(evt) =>
          dispatch({
            type: "setMessageError",
            value: getMessageError(evt.target.value),
          })
        }
        error={state.messageError}
        style={{ marginBottom: "1rem", height: "7rem" }}
      />

      {state.hasError && <Error>Please complete the fields correctly</Error>}

      <StyledGradientButton
        bold
        disabled={["success", "loading"].includes(mutationStatus)}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          const errors = {
            nameError: getNameError(state.name, true),
            emailError: getEmailError(state.email, true),
            phoneError: getPhoneError(state.phone, true),
            messageError: getMessageError(state.message),
          };
          if (hasError(errors)) {
            dispatch({ type: "setErrors", value: errors });
            return;
          }

          const variables = {
            name: state.name,
            phone: state.phone,
            email: state.email,
            message: state.message,
          };

          if (makeAppointment) {
            variables.modality = state.modality;
            variables.date = state.date;
          }

          onSubmit(variables);
        }}
        type="submit"
      >
        {mutationStatus === "loading" ? <Dots prefix="Sending" /> : buttonText}
      </StyledGradientButton>

      <SmallPrint>
        By pressing {buttonText}, you agree that PropertySimple and real estate
        professionals may call/text you about your inquiry. Message and data
        rates may apply. Message frequency varies. Help at{" "}
        {PHONE_NUMBERS.support}. Text STOP to cancel.
      </SmallPrint>

      <HelpLinks>
        <Link href="/privacy">Privacy Policy</Link> |{" "}
        <Link href="/terms-of-use">Terms and Conditions</Link>
      </HelpLinks>
    </Wrapper>
  );
}
