import React, { useContext, useEffect, useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { createInteractionMutation } from "./mutations";
import IsBotContext from "lib/context/IsBotContext";
import AuthenticatedUserContext from "lib/context/AuthenticatedUserContext";

function CreateInteraction({ input }) {
  const { loadingUser, userFromQuery } = useContext(AuthenticatedUserContext);
  const { userAgent, isBot } = useContext(IsBotContext);
  const [isComponentMounted, setIscomponentmounted] = useState(false);

  useEffect(() => setIscomponentmounted(true), []);

  if (!isComponentMounted) {
    return null;
  }

  if (process.env.DONT_CREATE_INTERACTIONS) {
    console.debug(
      `Skipping interaction creation because DONT_CREATE_INTERACTIONS is set`,
    );
    return null;
  }

  if (isBot) {
    console.debug(
      `Skipping interaction creation since current user's agent '${userAgent}' is marked as a bot`,
    );
    return null;
  }

  if (loadingUser) {
    console.debug("User is authenticated, waiting for its data to load...");
    return null;
  }

  return (
    <Mutation mutation={createInteractionMutation}>
      {(createInteraction, { called, data, error }) => {
        if (!called) {
          const variables = {
            input: {
              homebuyer_id: userFromQuery?.homebuyer?.id,
              browser_user_agent: userAgent,
              ...input,
            },
          };
          console.debug("Creating interaction...", { variables });
          createInteraction({ variables });
        }
        if (error) {
          console.error("There was an error creating an interaction", error);
        }

        return null;
      }}
    </Mutation>
  );
}

CreateInteraction.propTypes = {};

export default CreateInteraction;
