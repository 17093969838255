import React, { Fragment } from "react";
import styled from "styled-components";
import { formatDate } from "lib/date";
import { useContactModalContext } from "../state";

const EditButton = styled.span`
  color: var(--clr-pink);
  cursor: pointer;
  font-size: 1rem;
`;

export default function DateAndModalitySubtitle() {
  const { state, dispatch } = useContactModalContext();
  const date = state.date;
  return (
    <Fragment>
      {formatDate(date)}, {date.getUTCFullYear()}{" "}
      <EditButton
        onClick={() => dispatch({ type: "setStep", value: "selectDate" })}
      >
        edit
      </EditButton>
    </Fragment>
  );
}
