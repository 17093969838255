import React, { useReducer, createContext, useContext } from "react";

export function hasError(state) {
  return !!(
    state.nameError ||
    state.emailError ||
    state.phoneError ||
    state.messageError
  );
}

function reducer(state, action) {
  if (action.type === "setStep") {
    return { ...state, step: action.value };
  }
  if (action.type === "setModality") {
    return { ...state, modality: action.value };
  }
  if (action.type === "setDate") {
    return { ...state, date: action.value };
  }
  if (action.type === "setSubtitle") {
    return { ...state, subtitle: action.value };
  }
  if (action.type === "setPhone") {
    const newState = {
      ...state,
      phone: action.value,
      phoneError: "",
      phoneTouched: true,
    };
    window.localStorage.setItem("phone", action.value);
    return { ...newState, hasError: hasError(newState) };
  }
  if (action.type === "setPhoneError") {
    return { ...state, phoneError: action.value };
  }
  if (action.type === "setEmail") {
    const newState = {
      ...state,
      email: action.value,
      emailError: "",
      emailTouched: true,
    };
    window.localStorage.setItem("email", action.value);
    return { ...newState, hasError: hasError(newState) };
  }
  if (action.type === "setEmailError") {
    return { ...state, emailError: action.value };
  }
  if (action.type === "setName") {
    const newState = {
      ...state,
      name: action.value,
      nameError: "",
      nameTouched: true,
    };
    window.localStorage.setItem("name", action.value);
    return { ...newState, hasError: hasError(newState) };
  }
  if (action.type === "setNameError") {
    return { ...state, nameError: action.value };
  }
  if (action.type === "setMessage") {
    const newState = { ...state, message: action.value, messageError: "" };
    return { ...newState, hasError: hasError(newState) };
  }
  if (action.type === "setMessageError") {
    return { ...state, messageError: action.value };
  }
  if (action.type === "setErrors") {
    return {
      ...state,
      ...action.value,
      hasError: hasError(action.value),
    };
  }
  return state;
}

export function useContactModalReducer({ subtitle, makeAppointment, message }) {
  return useReducer(reducer, {
    step: makeAppointment ? "selectDate" : "collectMessage",
    subtitle,
    modality: "in_person",
    date: new Date(),
    phone: window.localStorage.getItem("phone") || "",
    phoneError: "",
    phoneTouched: false,
    email: window.localStorage.getItem("email") || "",
    emailError: "",
    emailTouched: false,
    name: window.localStorage.getItem("name") || "",
    nameError: "",
    nameTouched: false,
    message,
    messageError: "",
    hasError: false,
  });
}

export const ContactModalContext = createContext();

export function useContactModalContext() {
  return useContext(ContactModalContext);
}

export function ContactModalContextProvider({ state, dispatch, children }) {
  return (
    <ContactModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ContactModalContext.Provider>
  );
}
