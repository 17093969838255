export const extraLightGrayColor = "#F1F1F1";
export const lightGrayColor = "#999999";
export const grayColor = "#424143";
export const green = "#4CAF50";
export const greenColor = green;
export const lightGreen = "#4CAF50";
export const lightGreenColor = lightGreen;
export const red = "#CD2950";
export const redColor = red;
export const black = "#424143";
export const lightGrayRGBValues = "0,0,0";

export const pinkColor = "#FF3867";
export const purpleColor = "#AC00BD";

export const orangeColor = "#F15A29";
export const yellowColor = "#FFCB1F";
export const blueColor = "#53C9F7";
