import React, { Fragment } from "react";
import styled from "styled-components";
import { colors } from "@propertysimple/styles";
import { useContactModalReducer, ContactModalContextProvider } from "./state";
import { Modal } from "@propertysimple/components";
import CollectLeadDataForm from "./CollectLeadDataForm";
import SelectDateAndModalityForm from "./SelectDateAndModalityForm";
import FinalState from "./FinalState";

const ModalBody = styled.div`
  width: 100%;
  background: ${colors.BG};
  padding: 1.25rem 1.25rem;
  h1 {
    font-size: 1.25rem;
    margin: 0 0 1.25rem;
    text-align: center;
  }
`;

const defaultOnClose = () => console.error("onClose is not defined");

export default function ContactModal({
  initialMessageBody: message,
  photo,
  mutationStatus,
  title,
  subtitle,
  onClose = defaultOnClose,
  onSubmit,
  makeAppointment,
}) {
  const [state, dispatch] = useContactModalReducer({
    subtitle,
    makeAppointment,
    message,
  });
  return (
    <Modal
      width="390px"
      mobileBreak="422px"
      closeModal={onClose}
      closeButtonStyle={{ color: "#bfbfbf" }}
      preventEasyClose
    >
      <ContactModalContextProvider {...{ state, dispatch }}>
        <ModalBody>
          {["success", "error"].includes(mutationStatus) ? (
            <FinalState {...{ mutationStatus, onClick: onClose }} />
          ) : (
            <Fragment>
              <h1>{title}</h1>
              {state.step === "selectDate" ? (
                <SelectDateAndModalityForm {...{ photo }} />
              ) : (
                <CollectLeadDataForm
                  {...{ mutationStatus, onSubmit, makeAppointment }}
                />
              )}
            </Fragment>
          )}
        </ModalBody>
      </ContactModalContextProvider>
    </Modal>
  );
}
