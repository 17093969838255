export const currencyFormatWithoutDecimals = (number) => {
  number = parseFloat(number);
  if (!number) {
    return number;
  }
  if (number <= 100) {
    number = number.toFixed(2);
  } else {
    number = Math.round(number);
  }
  number = number.toString();
  if (number.endsWith(".00")) {
    number = number.replace(".00", "");
  }
  return "$" + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export function isObjectEmpty(obj) {
  for (let prop in obj) {
    //it's safer to use the original method
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

export const isNodeVisible = (node) => {
  const rect = node.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const formatNumberWithDelimiters = (number) => {
  return number && number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const processImage = (imageSrc, size) => {
  if (size === "origin") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}`;
  } else if (size === "ogtags") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=700&h=400`;
  } else if (size === "primaryListingImage") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=1400&h=1000`;
  } else if (size === "secondaryListingImage") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=800&h=400`;
  } else if (size === "relatedDataImagePrimarySquare") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=400&h=400`;
  } else if (size === "relatedDataImageSecondarySquare") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=200&h=200`;
  } else if (size === "relatedDataImagePrimaryWide") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=800&h=400`;
  } else if (size === "relatedDataImageSecondaryWide") {
    return `https://img.propertysimple.com/?u=${encodeURIComponent(imageSrc)}&w=400&h=200`;
  } else if (size.width && size.height) {
    return `https://img.propertysimple.com?w=${size.width}&h=${size.height}&u=${encodeURIComponent(imageSrc)}`;
  } else {
    console.error("Unhandled size", size);
  }

  return `https://img.propertysimple.com/?u=${imageSrc}`;
};

const processImageWithImageServer = (url, params = {}) => {
  const imageServerUrl = new URL("https://img.propertysimple.com/");
  const originalUrl = new URL(url);

  if (originalUrl.host === "img.propertysimple.com") {
    params.u = originalUrl.searchParams.get("u");
  } else {
    originalUrl.search = "";
    params.u = originalUrl.toString();
  }

  Object.entries(params).forEach(([key, value]) => {
    imageServerUrl.searchParams.append(key, value);
  });

  return imageServerUrl.toString();
};

const S3_URL =
  "https://property-simple-images.s3.us-west-2.amazonaws.com/agents";
const IMGIX_URL = "https://property-simple-images.imgix.net";

const hashToQueryString = (hash) => new URLSearchParams(hash).toString();

export const processBusinessCardPhoto = (businessCardPhoto, params = {}) => {
  return processImageWithImageServer(businessCardPhoto, params);
};

const selectText = (editableEl, selectionStart, selectionEnd) => {
  const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);
  if (isIOS) {
    const range = document.createRange();
    range.selectNodeContents(editableEl);

    const selection = window.getSelection(); // current text selection
    selection.removeAllRanges();
    selection.addRange(range);
    editableEl.setSelectionRange(selectionStart, selectionEnd);
  } else {
    editableEl.select();
  }
};

export const copyTextToClipboard = (value) => {
  const el = document.createElement("textarea"); // temporary element
  el.value = value;

  el.style.position = "absolute";
  el.style.top = "0px";
  el.style.left = "-9999px";
  el.readOnly = true; // avoid iOs keyboard opening
  el.contentEditable = "true";

  document.body.appendChild(el);

  selectText(el, 0, value.length);

  document.execCommand("copy");
  document.body.removeChild(el);
};

// return !phone || phone === '' || /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phone)
export const formatPhone = (phone) => {
  return phone
    .replace(/^ /, "")
    .replace(/[^ 0-9()-+]/g, "")
    .replace(/ {2,}/g, " ")
    .substring(0, 10);
};

export const addBlankTargets = (s) => {
  return ("" + s).replace(/<a\s+href=/gi, '<a target="_blank" href=');
};

// At least Safari on MacOS and Chrome on iOS change the order of hash elements
// when using object spread syntax, so this method is added to avoid that
// checkboxes change their order after check them.
export const setObjectKeyPreservingOrder = ({ checkboxes, key, value }) => {
  const result = {};
  Object.keys(checkboxes).forEach((checkboxKey) => {
    let checkbox = checkboxes[checkboxKey];
    if (checkboxKey === key) {
      checkbox = value;
    }
    result[checkboxKey] = checkbox;
  });
  return result;
};

export const categoryName = ({
  category,
  listingStatus,
  isActive,
  deleted,
  isSold,
}) => {
  if (isSold) {
    if (category === "Rent") return "Rented";
    return "Sold";
  }
  if (!deleted && listingStatus === "Pending") return "Pending";
  if (!isActive) return "Off Market";
  if (category === "Rent") return "For Rent";
  return "For Sale";
};

export const getUrlParam = (name) => {
  let parameters = new window.URLSearchParams(window.location.search);
  return parameters.get(name);
};
