import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";

const Wrapper = styled.div`
  text-align: center;
  h1 {
    font-size: 1.25rem;
    margin: 0 0 0.5rem;
  }
  p {
    line-height: 1.5em;
    margin: 0 0 1.5rem;
  }
  svg {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  button {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

function Status({ icon, iconColor, title, bodyText, onClick }) {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={icon} color={iconColor} />
      <h1>{title}</h1>
      <p>{bodyText}</p>
      <button className="btn btn-muted btn-compact" onClick={onClick}>
        Ok
      </button>
    </Wrapper>
  );
}

function Success({ onClick }) {
  return (
    <Status
      icon={faCheckCircle}
      iconColor={"var(--clr-success)"}
      title={"Thanks, you're all set!"}
      bodyText={`
        Info has been sent to the agent.
        They will be reaching out to you shortly.
      `}
      onClick={onClick}
    />
  );
}

function Error({ onClick }) {
  return (
    <Status
      icon={faTimesCircle}
      iconColor={"var(--clr-error)"}
      title={"Oops! Something went wrong"}
      bodyText={`
        Please try again later, or contact our support.
      `}
      onClick={onClick}
    />
  );
}

export default function FinalState({ mutationStatus, onClick }) {
  if (mutationStatus === "success") return <Success {...{ onClick }} />;
  if (mutationStatus === "error") return <Error {...{ onClick }} />;
  return null;
}
