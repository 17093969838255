import { gql } from "@apollo/client";

export const createInteractionMutation = gql`
  mutation createInteraction($input: CreateInteractionInput!) {
    createInteraction(create_interaction_input: $input) {
      interaction_name
      id
      __typename
    }
  }
`;
