import React from "react";
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import { lightGrayColor } from "../../lib/colors";
import styled from "styled-components";
import RoundImage from "components/RoundImage";
import { faPhone, faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { propType } from "graphql-anywhere";
import { processBusinessCardPhoto } from "../../lib/util";
import { A } from "../buttonLinkCss";
import Link from "next/link";
import { profileHref } from "lib/urls";
import { formatPhoneNumber } from "lib/strings";

export const AgentInformationCrmUserMock = ({
  fullName = "Jeff Fisher",
} = {}) => ({
  id: 1,
  brokerageName: "RE/MAX Fine Properties",
  business_card_photo: "/static/images/placeholders/jeff.png",
  email: "jeff@arizonasrealty.com",
  fullName,
  twilio_phone_number: "(234) 342-3948",
  brokerage: {
    logo_url: "/static/images/remax-logo.png",
  },
  user: {
    id: 1,
    user_name: "jeff-fisher",
  },
});

export const AgentInformationCrmUserFragment = gql`
  fragment AgentInformationCrmUserFragment on CrmUser {
    id
    brokerageName: brokerage_name
    business_card_photo
    avatar_centered
    email
    fullName: name
    twilio_phone_number

    user {
      id
      user_name
    }

    brokerage {
      id
      logo_url
    }
  }
`;

const Card = styled.address`
  align-items: center;
  display: flex;
  flex-flow: row;
  font-size: 1rem;
  font-style: normal;
  justify-content: center;
`;

const Paragraph = styled.div`
  display: block;
  margin: 0 auto 0.2rem auto;
  text-align: ${(props) => props.align || "center"};
  word-break: break-word;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0;
`;

const StyledRoundImage = styled(RoundImage)`
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

const agentImageSizeInPixels = 75;

function AgentInformation({
  crmUser = {},
  loading,
  title,
  displayLinkToProfile,
}) {
  const {
    brokerageName,
    business_card_photo: businessCardPhoto,
    brokerage,
    fullName,
    email,
    twilio_phone_number,
    user,
  } = crmUser || {};
  const { user_name: username } = user || {};
  const avatarImageAltText = `${fullName}'s avatar`;
  let avatarUrl = businessCardPhoto || brokerage?.logo_url;
  avatarUrl =
    avatarUrl &&
    processBusinessCardPhoto(avatarUrl, {
      h: agentImageSizeInPixels * 2,
      w: agentImageSizeInPixels * 2,
    });
  const profileURL = "/" + username;
  const formattedPhoneNumber = crmUser.twilio_phone_number
    ? formatPhoneNumber(twilio_phone_number)
    : null;

  return (
    <div>
      {title && <Title>{title}</Title>}
      <Card>
        <StyledRoundImage
          loading={loading}
          alt={avatarImageAltText}
          border={false}
          size={agentImageSizeInPixels}
          src={avatarUrl}
          style={{ marginRight: "1rem" }}
        />
        <div>
          <Paragraph align="left" style={{ fontWeight: "bold" }}>
            {fullName}
          </Paragraph>
          <Paragraph
            align="left"
            style={{ color: lightGrayColor, marginBottom: "0.5rem" }}
          >
            {brokerageName}
          </Paragraph>
        </div>
      </Card>
      {displayLinkToProfile && (
        <Link href={profileHref} as={profileURL} passHref legacyBehavior>
          <A
            horizontalPadding="2rem"
            name="outlinedPink"
            fontSize="0.9rem"
            rounded
            verticalPadding="0.375rem"
            style={{ display: "inline-block", marginBottom: "0.5rem" }}
            disabled={loading}
          >
            View&nbsp;Profile
          </A>
        </Link>
      )}
      {email && (
        <Paragraph
          align="left"
          style={{
            color: lightGrayColor,
            display: "flex",
            alignItems: "center",
            marginBottom: "0.375rem",
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            height="1rem"
            style={{ marginRight: "0.5rem" }}
          />
          {email}
        </Paragraph>
      )}
      {formattedPhoneNumber && (
        <Paragraph
          align="left"
          style={{
            color: lightGrayColor,
            display: "flex",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            height="1rem"
            style={{ marginRight: "0.5rem" }}
          />
          {formattedPhoneNumber}
        </Paragraph>
      )}
    </div>
  );
}

AgentInformation.propTypes = {
  crmUser: propType(AgentInformationCrmUserFragment),
  displayLinkToProfile: PropTypes.bool,
  title: PropTypes.string,
};

export default AgentInformation;
